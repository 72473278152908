import React from 'react';

import Avatar from '@components/avatar/Avatar.tsx';

import { RenderColsT } from '../../types.ts';

const renderListIntegrationNames: RenderColsT<'listIntegrationNames'> = function ({
    name: colName,
    item,
}) {
    let content;
    let className;

    const integration = item as { _id: string; name: string; logoSrc: string };

    if (colName === 'name') {
        content = (
            <>
                <div className={`v2table__preview _ROW _hiddenAvatar _minSize`}>
                    <div className="v2table__previewAvatar">
                        <Avatar className="_notRad" image={integration.logoSrc} />
                    </div>
                    <div className="v2table__previewInfo">
                        <div className="v2table__previewInfoInner">
                            <div className="v2table__text">{integration.name || '–'}</div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return { content, className };
};

export default renderListIntegrationNames;
