import React from 'react';

import Avatar from '@components/avatar/Avatar.tsx';
import { CorporationT } from '@global/models/Corporation.ts';

import { RenderColsT } from '../../types.ts';

const renderListCorporations: RenderColsT<'listCorporations'> = function ({ name: colName, item }) {
    let content;
    let className;

    const corporation = item as Pick<CorporationT, '_id' | 'opfName' | 'fullName' | 'inn'> & {
        logoSrc?: string;
    };

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__preview _ROW">
                    <div className="v2table__previewAvatar">
                        <Avatar
                            image={corporation.logoSrc}
                            firstSymb={corporation.fullName?.[0] || corporation.opfName?.[0]}
                        />
                    </div>
                    <div className="v2table__previewInfo">
                        <div className="v2table__previewInfoInner">
                            <div className="v2table__text">{corporation.opfName},</div>{' '}
                            <div className="v2table__text _grey">ИНН&nbsp;{corporation.inn}</div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return { content, className };
};

export default renderListCorporations;
