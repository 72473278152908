import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import Icon from '@components/icon/Icon.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';
import getWidthText from '@functions/getWidthText';
import { setCursorSupport } from '@functions/supportHandler.ts';

import asyncInputHandler from './methods/asyncInputHandler.ts';
import cityListHandler from './methods/cityListHandler.ts';
import fileHandler from './methods/fileHandler.ts';
import filesHandler from './methods/filesHandler.ts';

import WidgetFieldI from './types.ts';

import renderAction from './renders/renderAction.tsx';
import renderCheckbox from './renders/renderCheckbox.tsx';
import renderCheckboxes from './renders/renderCheckboxes.tsx';
import renderDate from './renders/renderDate.tsx';
import renderField from './renders/renderField.tsx';
import renderFile from './renders/renderFile.tsx';
import renderFiles from './renders/renderFiles.tsx';
import renderInput from './renders/renderInput.tsx';
import renderList from './renders/renderList.tsx';
import renderSelect from './renders/renderSelect.tsx';
import renderSwitch from './renders/renderSwitch.tsx';
import renderText from './renders/renderText.tsx';

class WidgetField
    extends React.Component<WidgetFieldI['props'], WidgetFieldI['state']>
    implements WidgetFieldI
{
    parent: WidgetFieldI['parent'];

    constructor(props: WidgetFieldI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    cityListHandler = cityListHandler;
    asyncInputHandler = asyncInputHandler;
    fileHandler = fileHandler;
    filesHandler = filesHandler;

    renderAction = renderAction;

    renderField = renderField;

    renderInput = renderInput;
    renderSelect = renderSelect;
    renderDate = renderDate;
    renderCheckbox = renderCheckbox;
    renderFile = renderFile;
    renderText = renderText;
    renderCheckboxes = renderCheckboxes;
    renderList = renderList;
    renderSwitch = renderSwitch;
    renderFiles = renderFiles;

    render() {
        const { loadingKey } = this.state;
        const {
            support,
            className = '',
            type,
            iserror,
            disabled,
            checkboxesCol,
            getSupportInfo,
            textarea,
            fieldClassName,
            areaCalcHeight,
        } = this.props;
        const classes = [
            ...(type !== 'checkboxes' ? [] : [checkboxesCol ? '_checkboxescol' : '_checkboxesrow']),
            ...(type === 'input' && textarea ? ['_textarea'] : []),
            ...(className ? [className] : []),
            `_type${type}`,
            ...(iserror ? ['_error'] : []),
            ...(disabled ? ['_disabled'] : []),
            ...(fieldClassName ? [fieldClassName] : []),
            ...(areaCalcHeight ? ['_calcHeight'] : []),
        ];

        const supportInfo = getSupportInfo ? getSupportInfo() : undefined;
        const supportProps =
            supportInfo &&
            setCursorSupport(
                {
                    _id: `widgetField-support-${supportInfo.text}`,
                    content: supportInfo.text,
                    dir: 'top',
                },
                { targetClassName: '.v2widgetField__supportInfo', className: '' },
            );

        const fillWidth =
            !support ||
            ['file', 'text', 'checkbox', 'checkboxes', 'files'].includes(type) ||
            textarea;

        return (
            <div ref={this.parent} className={`v2widgetField ${classes.join(' ')}`}>
                {support && type !== 'file' && (
                    <div className="v2widgetField__support">{support}:</div>
                )}
                <div
                    className="v2widgetField__box"
                    style={{
                        maxWidth: fillWidth
                            ? '100%'
                            : `calc(100% - ${getWidthText({ text: `${support}:`, size: 14 }) + 6}px)`,
                    }}
                >
                    <LoaderBlock
                        className="v2widgetField__loader"
                        isShow={!!loadingKey}
                        loaderClassName="_main"
                    />
                    {this.renderField()}
                    {getSupportInfo && (
                        <Animate
                            className={`v2widgetField__supportInfo ${supportInfo?.className || ''}`}
                            {...supportProps}
                            isShow={!!supportInfo}
                        >
                            <Icon name="info" />
                        </Animate>
                    )}
                    {this.renderAction()}
                </div>
            </div>
        );
    }
}

export default WidgetField;
