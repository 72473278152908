import { MouseEvent } from 'react';

import { CursorListsT, CustomListenerT, ListenerT } from '@global/types';
import { dispatcher, store } from '@redux/redux';

type SettingsT = {
    targetClassName: string;
    className?: string;
    preventDefault?: boolean;
    stopPropagation?: boolean;
};

const deleteCursorList = async function ({ id }: { id: CursorListsT['_id'] }): Promise<void> {
    const cursorList = store.getState().cursorList.map((item) => ({ ...item }));

    const index = cursorList.findIndex((item) => item._id === id);

    if (index !== -1) {
        const cursorListItem = cursorList[index];

        if (cursorListItem.deleteCallback) {
            cursorListItem.deleteCallback();
        }

        cursorList.splice(index, 1);

        await dispatcher({ type: 'cursorList', data: cursorList });

        const observerIndex = observers.findIndex((item) => item.id === id);

        if (observerIndex !== -1) {
            observers[observerIndex].observer.disconnect();

            (document.removeEventListener as ListenerT<MouseEvent>)(
                'click',
                observers[observerIndex].documentClickHandler,
            );
            (document.removeEventListener as CustomListenerT)(
                'cursorListsClick',
                observers[observerIndex].documentClickHandler,
            );
            document.removeEventListener(
                'changeWidthWindow',
                observers[observerIndex].documentResizeHandler,
            );

            if (process.env.REACT_APP_SYSTEM === 'app') {
                (document.removeEventListener as CustomListenerT)(
                    'touchstart',
                    observers[observerIndex].documentClickHandler,
                );
            } else {
                window.removeEventListener(
                    'scroll',
                    observers[observerIndex].documentScrollHandler,
                );
            }

            observers.splice(observerIndex, 1);
        }
    }
};

const getSizeItem = function ({ settings, list }: { settings: SettingsT; list: CursorListsT }): {
    width: number;
    height: number;
} {
    const div = document.createElement('div');
    const divInner = document.createElement('div');

    div.classList.add('v2cursorLists__item');

    if (settings.className) {
        div.classList.add(settings.className);
    }

    divInner.classList.add('v2widget');
    divInner.classList.add('_cursorLists');

    const head = document.createElement('div');

    head.classList.add('v2widget__head');

    const content = document.createElement('div');

    content.classList.add('v2widget__content');

    if (!list.items) {
        divInner.appendChild(head);
    }

    divInner.appendChild(content);

    div.style.position = 'absolute';
    div.style.zIndex = '-1';
    div.style.top = '0';
    div.style.left = '0';
    div.style.opacity = '0';
    div.style.pointerEvents = 'none';

    div.appendChild(divInner);

    document.body.appendChild(div);

    const width = div.offsetWidth;
    const height = div.offsetHeight;

    div.remove();

    return { width, height };
};

const getPosition = function ({
    target,
    list,
    settings,
}: {
    target: HTMLElement;
    list: CursorListsT;
    settings: SettingsT;
}): { left: number; top: number; dir: CursorListsT['dir'] } {
    const targetLeft = target.getBoundingClientRect().x;
    const targetTop = target.getBoundingClientRect().y;
    const { width, height } = getSizeItem({ list, settings });
    const margin = 16;
    let leftDelta = 0;
    let topDelta = 0;
    let dir = list.dir;

    if (list.dir === 'top') {
        leftDelta = 0;
        topDelta = -target.offsetHeight / 2 - height / 2 - margin;
    }

    if (list.dir === 'bottom') {
        leftDelta = 0;
        topDelta = target.offsetHeight / 2 + height / 2 + margin;
    }

    let resultLeft = targetLeft + target.offsetWidth / 2 - width / 2 + leftDelta;
    let resultTop = targetTop + target.offsetHeight / 2 - height / 2 + topDelta;

    if (resultTop + height > document.documentElement.clientHeight - 20) {
        const reversePosition = getPosition({
            target,
            list: { ...list, dir: 'top' },
            settings,
        });

        dir = 'top';

        resultLeft = reversePosition.left;
        resultTop = reversePosition.top;
    }

    return { left: resultLeft, top: resultTop, dir };
};

const observers: {
    id: string;
    observer: MutationObserver;
    documentClickHandler: (e: MouseEvent | CustomEvent<{ e: MouseEvent }>) => void;
    documentResizeHandler: () => void;
    documentScrollHandler: (e: Event) => void;
}[] = [];

const addCursorList = async function ({
    e,
    list,
    settings,
}: {
    e: Pick<MouseEvent, 'target'>;
    list: CursorListsT;
    settings: SettingsT;
}): Promise<void> {
    const cursorList = store.getState().cursorList.map((item) => ({ ...item }));
    const target = (e.target as HTMLElement).closest(settings.targetClassName) as HTMLElement;
    const oldItem = cursorList.find((item) => item._id === list._id);

    if (oldItem) {
        oldItem.items = list.items;
        oldItem.updatedKey = new Date().getTime();

        dispatcher({ type: 'cursorList', data: cursorList });
    }

    if (target && !oldItem) {
        const { left, top, dir } = getPosition({ target, list, settings });

        cursorList.push({
            ...list,
            position: { left, top },
            deleteCallback: list.deleteCallback,
            rowClassName: list.rowClassName,
            dir,
            className: settings.className,
        });

        if (list.addCallback) {
            list.addCallback();
        }

        const observer = new MutationObserver((ev: MutationRecord[]) => {
            if (ev[0].removedNodes) {
                const node = ev[0].removedNodes[0];

                if (node === target) {
                    observer.disconnect();

                    deleteCursorList({ id: list._id });
                }
            }
        });

        const documentClickHandler = (
            ev: MouseEvent | TouchEvent | CustomEvent<{ e: MouseEvent | TouchEvent }>,
        ) => {
            const node = document.querySelector(`.v2cursorLists__item[data-_id="${list._id}"]`);

            const eventTarget = (
                typeof ev.detail === 'object' ? ev.detail.e.target : ev.target
            ) as HTMLElement;

            if (
                node &&
                target &&
                eventTarget !== target &&
                !target.contains(eventTarget) &&
                eventTarget !== node &&
                !node.contains(eventTarget) &&
                (!list.checkClose || list.checkClose())
            ) {
                deleteCursorList({ id: list._id });
            }
        };

        const documentResizeHandler = () => {
            deleteCursorList({ id: list._id });
        };

        const documentScrollHandler = (scrollE: Event) => {
            const listParent = document.querySelector(
                `.v2cursorLists__item[data-_id="${list._id}"]`,
            ) as HTMLElement;

            if (!listParent?.contains(scrollE.target as HTMLElement)) {
                deleteCursorList({ id: list._id });
            }
        };

        observer.observe(target.parentNode!, { childList: true });

        observers.push({
            id: list._id,
            observer,
            documentClickHandler,
            documentResizeHandler,
            documentScrollHandler,
        });

        dispatcher({ type: 'cursorList', data: cursorList });

        setTimeout(() => {
            (document.addEventListener as CustomListenerT)(
                'cursorListsClick',
                documentClickHandler,
            );
            (document.addEventListener as ListenerT<MouseEvent>)('click', documentClickHandler);
            document.addEventListener('changeWidthWindow', documentResizeHandler);

            if (process.env.REACT_APP_SYSTEM === 'app') {
                (document.addEventListener as ListenerT<TouchEvent>)(
                    'touchstart',
                    documentClickHandler,
                );
            } else {
                window.addEventListener('scroll', documentScrollHandler, true);
            }
        }, 10);
    }
};

const setCursorList = function (
    list: Omit<CursorListsT, 'className'>,
    settings: SettingsT,
): {
    onClick: (e: Pick<MouseEvent, 'target' | 'preventDefault' | 'stopPropagation'>) => void;
} {
    const props = {
        onClick: (e: Pick<MouseEvent, 'target' | 'preventDefault' | 'stopPropagation'>) => {
            if (settings.preventDefault) {
                e.preventDefault();
            }
            if (settings.stopPropagation) {
                document.dispatchEvent(new CustomEvent('cursorListsClick', { detail: { e } }));

                e.stopPropagation();
            }

            const cursorList = store.getState().cursorList;

            if (cursorList.find((item) => item._id === list._id)) {
                deleteCursorList({ id: list._id });
            } else {
                addCursorList({ e, list, settings });
            }
        },
    };

    return props;
};

export { addCursorList, deleteCursorList, setCursorList };
