import React from 'react';

import Avatar from '@components/avatar/Avatar.tsx';
import IntegrationT from '@global/models/Integration.ts';
import { CursorActionsItemT } from '@global/types.ts';

import More from '../components/More.tsx';

import { RenderColsT } from '../../types.ts';

const renderIntegrations: RenderColsT<'integrations'> = function ({ name: colName, item }) {
    const integration = item as IntegrationT;
    let content;
    let className;

    if (colName === 'name') {
        content = (
            <>
                <div className={`v2table__preview _ROW _hiddenAvatar _mediumSize`}>
                    <div className="v2table__previewAvatar">
                        <Avatar className="_notRad" image={integration.logoSrc} />
                    </div>
                    <div className="v2table__previewInfo">
                        <div className="v2table__previewInfoInner">
                            <div className="v2table__text">{integration.title}</div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    if (colName === 'token') {
        const { otherData } = this.props;
        const getMoreActions = otherData?.getMoreActions as (data: {
            integration: IntegrationT;
        }) => CursorActionsItemT[];
        className = '_full _withMore';

        content = (
            <>
                {integration.token || '–'}
                <More
                    id={`integration-${integration._id}`}
                    items={getMoreActions({ integration })}
                />
            </>
        );
    }

    return { content, className };
};

export default renderIntegrations;
