import axios from 'axios';

import getHeaders from '../functions/getHeaders.ts';

import { FilterBlockT, FilterQueryT, ReqResponseT } from '../global/types.ts';

type ItemT = { _id: string } & Record<any, unknown>;

type ParamsT = {
    url: string;
    query: FilterQueryT[];
};

type ReturnT<I = ItemT, T = {}> = {
    items: I[];
    item: I;
    model: I;
    filter: FilterBlockT[];
    counter?: number;
    isLimit?: boolean;
} & Partial<Omit<T, 'items' | 'item' | 'filter' | 'counter' | 'isLimit'>>;

export default async function getListItems<I = ItemT, T = {}>({
    url,
    query = [],
}: ParamsT): Promise<ReturnT<I, T>> {
    let queryString = '';

    query.forEach((item) => {
        queryString += `${item.key}=${encodeURIComponent(item.value)}&`;
    });

    queryString = queryString.slice(0, -1);

    try {
        const response = await axios.get<ReqResponseT<ReturnT<I, T>>>(
            `${process.env.REACT_APP_API}/${url}?${queryString}`,
            {
                headers: getHeaders(),
            },
        );

        if (response.data) {
            const { success, data } = response.data;

            if (success) {
                return data;
            }

            return Promise.reject(response.data);
        }

        return Promise.reject();
    } catch (error) {
        return Promise.reject(error);
    }
}
