import React from 'react';

import DealT from '@global/models/Deal.ts';

import { RenderColsT } from '../../types.ts';

const renderListDeals: RenderColsT<'listDeals'> = function ({ name: colName, item }) {
    let content;
    let className;

    const deal = item as Pick<DealT, '_id' | 'ePhone' | 'number'> & {
        stepName: string;
        stepColor: string;
    };

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text">Сделка №{deal.number}</div>
            </>
        );
    }

    return { content, className };
};

export default renderListDeals;
