const integrations = {
    colsOrder: ['name', 'token'],
    cols: {
        name: {
            content: 'Название',
            width: 40,
        },
        token: {
            content: 'Токен',
            width: 60,
        },
    },
    deletesCols: [],
    url: 'integration',
    emptyInfo: {
        title: 'Интеграций пока нет',
        description: 'Вы можете добавить интеграцию по кнопке справа',
    },
} as const;

export default integrations;
