import React from 'react';

import BeelineUserT from '@global/models/BeelineUser.ts';

import BeelineTableI from '../../../../../views/crm/settings/components/integrations/components/inner/components/beelineTable/types.ts';
import More from '../components/More.tsx';

import { RenderColsT } from '../../types.ts';

const renderBeelineUsers: RenderColsT<'beelineUsers'> = function ({ name: colName, item }) {
    let content;
    let className;
    const user = item as BeelineUserT;

    if (colName === 'phone') {
        content = <div className="v2table__text">{user.phone}</div>;
    }

    if (colName === 'user') {
        const { otherData } = this.props;
        const getMoreActions = otherData?.getMoreActions as BeelineTableI['getMoreActions'];

        className = '_full';

        content = (
            <>
                {this.renderUser({ item: user, isThin: true })}
                <More
                    id={`beelineUser-${user._id}`}
                    items={getMoreActions({ beelineUser: user })}
                />
            </>
        );
    }

    return { content, className };
};

export default renderBeelineUsers;
