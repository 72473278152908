import React from 'react';

import Avatar from '@components/avatar/Avatar.tsx';
import getCurrentCorporation, {
    CurrentCorporationInfoT,
} from '@functions/crm/getCurrentCorporation.ts';
import getFormatedDate from '@functions/getFormatedDate.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import UserT from '@global/models/User.ts';
import { CursorActionsItemT } from '@global/types.ts';

import More from '../components/More.tsx';

import { RenderColsT, TableBlockCorporationT } from '../../types.ts';

const renderUsers: RenderColsT<'users'> = function ({
    name: colName,
    item,
    corporationIndex,
    ...props
}) {
    const user = item as UserT;
    let content;
    const isAdmin = this.checkAdmin();
    const corporation = props.corporation as TableBlockCorporationT<{
        roleName: string;
        proxy: { status: string; statusText: string; actionDate: string; id: string };
    }>;

    if (corporationIndex === 0) {
        if (colName === 'name') {
            const text = [
                user.fullName,
                ...(isAdmin ? [user.email] : []),
                ...(isAdmin && user.phone ? [`<span class="_NOWRAP">${user.phone}</span>`] : []),
            ];

            content = (
                <>
                    {user.logoSrc ? (
                        <div className={`v2table__preview _ROW`}>
                            <div className="v2table__previewAvatar">
                                <Avatar className="_circle _grey" image={user.logoSrc} />
                            </div>
                            <div className="v2table__previewInfo">
                                <div className="v2table__previewInfoInner">
                                    <div
                                        className="v2table__text"
                                        dangerouslySetInnerHTML={{ __html: text.join(', ') }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="v2table__text"
                            dangerouslySetInnerHTML={{ __html: text.join(', ') }}
                        ></div>
                    )}
                </>
            );
        }

        if (colName === 'email') {
            content = (
                <>
                    <div className="v2table__text">{user.email}</div>
                </>
            );
        }

        if (colName === 'status') {
            let lastAuthDateStr;
            let statusClassName = '';

            if (user.status === 'online') {
                statusClassName = '_successColor';
            } else if (user.status === 'offline') {
                statusClassName = '_grey';
            } else {
                statusClassName = '_mediumColor';
            }

            if (item.lastAuthDateTime) {
                const lastAuthDateInst = new Date(user.lastAuthDateTime);
                const dateDiff = (new Date().getTime() - lastAuthDateInst.getTime()) / 1_000;

                if (dateDiff < 60) {
                    lastAuthDateStr = 'Только что';
                } else if (dateDiff < 60 * 60) {
                    lastAuthDateStr = `${Math.round(dateDiff / 60)} мин. назад`;
                } else if (dateDiff < 60 * 60 * 24) {
                    lastAuthDateStr = `${Math.round(dateDiff / (60 * 60))} ч. назад`;
                } else if (dateDiff < 60 * 60 * 24 * 30) {
                    lastAuthDateStr = `${Math.round(dateDiff / (60 * 60 * 24))} д. назад`;
                } else {
                    lastAuthDateStr = 'давно';
                }
            }

            content = (
                <>
                    <div className={`v2table__text _mediumWeight ${statusClassName}`}>
                        {user.statusText}
                        {user.status === 'offline' && user.lastAuthDateTime > 0 && (
                            <>
                                <br />({lastAuthDateStr})
                            </>
                        )}
                    </div>

                    {(user.status === 'inactive' ||
                        user.corporations.filter((userCorp) => userCorp._id !== 'empty').length ===
                            0) && (
                        <div className="v2table__text _grey _block">
                            Регистрация{' '}
                            {getFormatedDate({
                                date: new Date(user.createdDate),
                                type: 'full',
                            })}
                        </div>
                    )}
                </>
            );
        }
    }

    if (colName === 'role') {
        content = (
            <>
                <div className="v2table__text">{corporation.roleName || 'Нет роли'}</div>
            </>
        );
    }

    if (colName === 'proxy') {
        const { otherData } = this.props;
        const getMoreActions = otherData?.getMoreActions as (data: {
            user: UserT;
        }) => CursorActionsItemT[];
        const signContract = () => {
            handlerPopup<'crm'>('signContractPopup', {
                isShow: true,
                items: [corporation.proxy.id],
                renderStatus: () => (
                    <div className={`v2table__proxyStatus _popup _${corporation.proxy?.status}`}>
                        {corporation.proxy.statusText}
                    </div>
                ),
                successCallback: this.updateItems.bind(this),
                type: 'userProxy',
            });
        };

        const showContractPopup = () => {
            handlerPopup<'crm'>('joinContractPopup', {
                isShow: true,
                type: 'userProxy',
                info: item.fullName,
                userId: item._id,
                successCallback: this.updateItems.bind(this),
            });
        };

        content = (
            <>
                {corporationIndex === 0 && (
                    <More
                        id={`user-${user._id}`}
                        items={getMoreActions({ user })}
                        stopPropagation={true}
                    />
                )}
                {corporation.proxy?.status ? (
                    <>
                        {' '}
                        <div className="v2table__proxy _col">
                            <div className={`v2table__proxyStatus _${corporation.proxy?.status}`}>
                                {corporation.proxy?.statusText}
                            </div>
                            {corporation.proxy?.status === 'complete' && (
                                <>
                                    <div className="v2table__proxyDescription">
                                        Действует:
                                        <br /> до{' '}
                                        {getFormatedDate({
                                            date: new Date(corporation.proxy.actionDate),
                                        })}
                                    </div>
                                </>
                            )}
                            {corporation._id === user.idOfCurrentCorporation &&
                                getCurrentCorporation({ user })?.role === 'holder' &&
                                !(getCurrentCorporation({ user }) as CurrentCorporationInfoT)
                                    ?.isAdmin && (
                                    <>
                                        {['required', 'inactive'].includes(
                                            corporation.proxy?.status,
                                        ) && (
                                            <>
                                                <div className="v2table__proxyButton">
                                                    <div
                                                        className="v2table__button _mediumWeight _row _click"
                                                        onClick={showContractPopup}
                                                    >
                                                        Создать
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {['executorSign', 'wait'].includes(
                                            corporation.proxy?.status,
                                        ) && (
                                            <>
                                                <div className="v2table__proxyButton">
                                                    <div
                                                        className="v2table__button _mediumWeight _row _click"
                                                        onClick={signContract}
                                                    >
                                                        Подписать
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            {['corporationSign', 'wait'].includes(corporation.proxy?.status) &&
                                item._id === user._id &&
                                user.idOfCurrentCorporation === corporation._id && (
                                    <>
                                        <div className="v2table__proxyButton">
                                            <div
                                                className="v2table__button  _mediumWeight_row _click"
                                                onClick={signContract}
                                            >
                                                Подписать
                                            </div>
                                        </div>
                                    </>
                                )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="v2table__text">–</div>
                    </>
                )}
            </>
        );
    }

    return { content };
};

export default renderUsers;
