const tasks = {
    colsOrder: [
        'number',
        'responsible',
        'creater',
        'key',
        'target',
        'description',
        'status',
    ],
    cols: {
        number: {
            content: '№',
            width: 7,
        },
        responsible: {
            content: 'Ответственный',
            width: 15,
        },
        creater: {
            content: 'Поставил задачу',
            width: 17,
        },
        key: {
            content: 'Тип задачи',
            width: 13,
        },
        target: {
            content: 'Объект',
            width: 13,
        },
        description: {
            content: 'Описание',
            width: 21,
        },
        status: {
            content: 'Статус',
            width: 14,
        },
    },
    deletesCols: [],
    url: 'task',
    emptyInfo: {
        title: 'Задач пока нет',
        description: 'Вы можете создать задачу по кнопке справа',
    },
} as const;

export default tasks;
