import changePage from '@functions/changePage.ts';
import setAsyncTimer from '@functions/setAsyncTimer.ts';
import getListItems from '@requests/getListItems.ts';

import I from '../types.ts';

const getModel: I['getModel'] = async function (this: I) {
    if (this.url) {
        try {
            if (0) {
                await setAsyncTimer(1_000);
            }

            const query = this.getQuery ? this.getQuery({}) : [];
            const { item, model } = await getListItems({ url: this.url, query });

            const resultModel = { ...(item || model) };

            if (query.find((queryItem) => queryItem.key === 'id')?.value === 'new') {
                resultModel.new = true;
            }

            if (this.updateModelBeforeSet) {
                this.updateModelBeforeSet(resultModel);
            }

            await this.setModel(resultModel);

            if (this.getModelInit) {
                this.getModelInit();
            }
        } catch (e) {
            const error = e as { code: number };

            if (error?.code === 404 && this.mainPageName) {
                changePage({ pageName: this.mainPageName });
            }
        }
    }
};

export default getModel;
