import React from 'react';

import Checkbox from '@components/checkbox/Checkbox.tsx';
import Tag from '@components/crm/tag/Tag.tsx';
import setSpacesInText from '@functions/setSpacesInText.ts';
import shortText from '@functions/shortText';
import ChatTemplateT from '@global/models/ChatTemplate.ts';

import More from '../components/More.tsx';

import ChatTemplatesI from '../../../../../views/crm/chatTemplates/types.ts';
import { RenderColsT } from '../../types.ts';

import Files from './chatTemplates/Files.tsx';

const renderChatTemplates: RenderColsT<'chatTemplates'> = function ({ name: colName, item }) {
    const { currents = [], currentsHandler } = this.props;
    let content;
    let className;
    const template = item as ChatTemplateT;
    const { name } = this.props;

    if (colName === 'name') {
        content = (
            <>
                {name === 'popupChatTemplates' ? (
                    <>
                        <div className="v2table__checkbox _COL _vertical">
                            <div className="v2table__checkboxBox">
                                <Checkbox
                                    name={template._id}
                                    value={
                                        !!currents!.find(
                                            (innerItem) => innerItem.id === template._id,
                                        )
                                    }
                                    onChange={() => {
                                        currentsHandler!(template._id, template, true);
                                    }}
                                />
                            </div>
                            <div
                                className="v2table__text _block"
                                dangerouslySetInnerHTML={{ __html: setSpacesInText(template.name) }}
                            ></div>
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            className="v2table__text"
                            dangerouslySetInnerHTML={{ __html: setSpacesInText(template.name) }}
                        ></div>
                    </>
                )}
            </>
        );
    }

    if (colName === 'tags') {
        content = (
            <>
                {template.tags.length ? (
                    <div className="v2table__tags">
                        {template.tags.map((tag) => (
                            <div className="v2table__tag" key={tag._id}>
                                <Tag color={tag.groupColor} isFull={true}>
                                    {tag.name}
                                </Tag>
                            </div>
                        ))}
                    </div>
                ) : (
                    <>–</>
                )}
            </>
        );
    }

    if (colName === 'content') {
        content = (
            <>
                {name === 'popupChatTemplates' ? (
                    <>
                        <div
                            className="v2table__text _block"
                            dangerouslySetInnerHTML={{ __html: setSpacesInText(template.content) }}
                        ></div>
                        <Files template={template} name="popupChatTemplates" />
                    </>
                ) : (
                    <>
                        <div
                            className="v2table__text"
                            dangerouslySetInnerHTML={{
                                __html: setSpacesInText(
                                    shortText({ str: template.content, stop: 200 }),
                                ),
                            }}
                        ></div>
                    </>
                )}
            </>
        );
    }

    if (colName === 'files') {
        const { otherData } = this.props;
        const getMoreActions = otherData?.getMoreActions as ChatTemplatesI['getMoreActions'];

        content = (
            <>
                <More
                    id={`template-${template._id}`}
                    items={getMoreActions({ template })}
                    stopPropagation={true}
                />
                <Files template={template} />
            </>
        );
    }

    return { content, className };
};

export default renderChatTemplates;
