type ParamsT = {
    text: string;
    size?: number;
    family?: string;
    weight?: number;
    letterSpacing?: number;
    isInput?: boolean;
    min?: number;
    max?: number;
};

export default function getWidthText({
    text,
    size = 16,
    family = 'PTRoot',
    weight = 500,
    letterSpacing = 0,
    isInput = false,
    min,
    max,
}: ParamsT): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    let width;

    context.font = `${weight} ${size}px ${family}`;

    // console.log(context);

    width = context.measureText(text).width;

    if (letterSpacing) {
        width += (text.length - 1) * letterSpacing;
    }

    let resultWidth = width + ((isInput && 2) || 0);

    if (min && resultWidth < min) {
        resultWidth = min;
    }

    if (max && resultWidth > max) {
        resultWidth = max;
    }

    return resultWidth;
}
