import lists from '../lists';

const listDealGroups = {
    ...lists,
    query: [{ key: 'type', value: 'dealGroups' }],
    emptyInfo: {
        title: 'Воронок пока нет',
        description: 'Они будут отображаться здесь',
    },
} as const;

export default listDealGroups;
