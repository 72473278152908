import React from 'react';

import Tag from '@components/crm/tag/Tag.tsx';
import getEndText from '@functions/getEndText.ts';
import getFormatedDate from '@functions/getFormatedDate.ts';
import shortText from '@functions/shortText';
import ChatMailingT from '@global/models/ChatMailing.ts';

import More from '../components/More.tsx';

import MailingsI from '../../../../../views/crm/mailings/types.ts';
import { RenderColsT } from '../../types.ts';

import Actions from './chatMailings/Actions.tsx';

const renderChatMailings: RenderColsT<'chatMailings'> = function ({ name: colName, item }) {
    const { loadings } = this.state;
    let content;
    let className;
    const mailing = item as ChatMailingT;

    if (colName === 'date') {
        content = (
            <div className="v2table__text">
                {getFormatedDate({ date: new Date(mailing.createdDate) })}
            </div>
        );
    }

    if (colName === 'tags') {
        content = (
            <>
                {mailing.tags.length ? (
                    <div className="v2table__tags">
                        {mailing.tags.map((tag) => (
                            <div className="v2table__tag" key={tag._id}>
                                <Tag color={tag.groupColor} isFull={true}>
                                    {tag.name}
                                </Tag>
                            </div>
                        ))}
                    </div>
                ) : (
                    <>–</>
                )}
            </>
        );
    }

    if (colName === 'content') {
        content = <>{shortText({ str: mailing.content!, stop: 200 })}</>;
    }

    if (colName === 'files') {
        content = (
            <>
                {mailing.files.length ? (
                    <>
                        {mailing.files.length}{' '}
                        {getEndText(mailing.files.length, ['файл', 'файла', 'файлов'])}
                    </>
                ) : (
                    <>
                        <div className="v2table__text _grey">Нет</div>
                    </>
                )}
            </>
        );
    }

    if (colName === 'actions') {
        const { otherData } = this.props;
        const mailingHandler = otherData?.mailingHandler as MailingsI['mailingHandler'];
        const getMoreActions = otherData?.getMoreActions as MailingsI['getMoreActions'];

        className = '_full _withMore';

        content = (
            <>
                <More
                    id={`mailing-${mailing._id}`}
                    items={getMoreActions({ mailing })}
                    stopPropagation={true}
                />
                <Actions
                    mailing={mailing}
                    loadingHandler={this.loadingHandler.bind(this)}
                    mailingHandler={mailingHandler}
                    loadingKey={loadings[mailing._id]}
                />
            </>
        );
    }

    return { content, className };
};

export default renderChatMailings;
