const actions = {
    logs: {
        icon: 'actions-logs-dark',
        text: 'Журнал логов',
    },
    delete: {
        icon: 'actions-delete',
        text: 'Удалить',
    },
    again: {
        icon: 'actions-repeat',
        text: 'Повторно отправить смс',
    },
    edit: {
        icon: 'actions-edit',
        text: 'Редактировать',
    },
    download: {
        icon: 'actions-download',
        text: 'Скачать',
    },
    repeat: {
        icon: 'actions-repeat',
    },
    checkError: {
        icon: 'actions-checkError',
    },
    open: {
        icon: 'actions-open',
        text: 'Открыть',
    },
    remove: {
        icon: 'actions-remove',
    },
    withdrawAmount: {
        icon: 'actions-withdraw',
    },
    targetBlank: {
        icon: 'actions-targetBlank',
        text: 'Открыть ссылку в новой вкладке',
    },
    copyLink: {
        icon: 'actions-copyLink',
        text: 'Скопировать ссылку',
    },
    start: {
        icon: 'actions-responsible',
    },
    pin: {
        icon: 'actions-pin',
    },
} as const;

export default actions;
