import React from 'react';

import ExecutorOrg from '@components/crm/ExecutorOrg.jsx';
import getUserName from '@functions/getUserName.ts';
import PayT from '@global/models/Pay.ts';

import { RenderColsT } from '../../types.ts';

const renderListPays: RenderColsT<'listPays'> = function ({ name: colName, item }) {
    let content;
    let className;

    const pay = item as Pick<PayT, '_id' | 'number'> & {
        executor: {
            firstName: string;
            secondName: string;
            thirdName: string;
            phone: string;
            organization: string;
            organizationShortName: string;
        };
    };

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text">
                    <b>Акт №{pay.number}</b>,{' '}
                </div>
                <div className="v2table__text">
                    {getUserName({ user: pay.executor })},{' '}
                    <div className="v2table__text _grey">{pay.executor.phone}</div>{' '}
                    <div className="v2table__executorOrg">
                        <ExecutorOrg name={pay.executor.organization} />
                    </div>
                </div>
            </>
        );
    }

    return { content, className };
};

export default renderListPays;
