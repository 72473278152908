import React from 'react';

import DealGroupT from '@global/models/DealGroup.ts';

import RecruitDealGroupsI from '../../../../../views/crm/recruit/components/dealGroups/types.ts';
import More from '../components/More.tsx';

import { RenderColsT } from '../../types.ts';

const renderRecruitDealGroups: RenderColsT<'recruitDealGroups'> = function ({
    name: colName,
    item,
}) {
    const dealGroup = item as DealGroupT;
    let content;
    let className;

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text">{dealGroup.name}</div>
            </>
        );
    }

    if (colName === 'dealsCount') {
        const { otherData } = this.props;
        const getMoreActions = otherData?.getMoreActions as RecruitDealGroupsI['getMoreActions'];

        content = (
            <>
                <div className="v2table__text">{dealGroup.dealsCount}</div>
                <More
                    id={`dealGroup-${dealGroup._id}`}
                    items={getMoreActions({ dealGroup })}
                    stopPropagation={true}
                />
            </>
        );
    }

    return { content, className };
};

export default renderRecruitDealGroups;
