import I from '../types.ts';

const getFilterCounter: I['getFilterCounter'] = function () {
    const { filterQuery } = this.state;
    const counters: string[] = [];

    if (filterQuery) {
        filterQuery.forEach((block) => {
            if (!counters.includes(block.name as string)) {
                counters.push(block.name as string);
            }
        });
    }

    return counters.length;
};

export default getFilterCounter;
