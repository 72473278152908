import React from 'react';

import DealGroupT from '@global/models/DealGroup.ts';

import { RenderColsT } from '../../types.ts';

const renderListDealGroups: RenderColsT<'listDealGroups'> = function ({ name: colName, item }) {
    let content;
    let className;

    const dealGroup = item as DealGroupT;

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text">{dealGroup.name}</div>
            </>
        );
    }

    return { content, className };
};

export default renderListDealGroups;
