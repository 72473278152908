const recruitDealGroups = {
    colsOrder: ['name', 'dealsCount'],
    cols: {
        name: {
            content: 'Название',
            width: 80,
        },
        dealsCount: {
            content: 'Кол-во сделок',
            width: 20,
        },
    },
    deletesCols: [],
    url: 'dealGroup',
    emptyInfo: {
        title: 'Воронок пока нет',
        description: 'Вы можете создать сделку по кнопке справа',
    },
} as const;

export default recruitDealGroups;
