import I from '../types.ts';

const setModel: I['setModel'] = async function (this: I, model) {
    return new Promise((resolve) => {
        this.setState(
            { model: { ...model }, id: model._id, updatedModelKey: new Date().getTime() },
            resolve,
        );
    });
};

export default setModel;
