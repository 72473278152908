const popups = {
    alert: { name: 'Alert' },
    filter: { name: 'Filter' },
    calendar: { name: 'Calendar' },
    logs: { name: 'Logs' },
    chatTemplates: { name: 'ChatTemplates' },
    paySign: { name: 'PaySign' },
    contractSign: { name: 'ContractSign' },
    payEdit: { name: 'PayEdit' },
    contractEdit: { name: 'ContractEdit' },
    payGroupUpload: { name: 'PayGroupUpload' },
    user: { name: 'User' },
    corporationEdit: { name: 'CorporationEdit' },
    corporationAmountDoc: { name: 'CorporationAmountDoc' },
    corporationCreate: { name: 'CorporationCreate' },
    corporationRoleSection: { name: 'CorporationRoleSection' },
    role: { name: 'Role' },
    doc: { name: 'Doc' },
    wallet: { name: 'Wallet' },
    executorCreate: { name: 'ExecutorCreate' },
    executorMvd: { name: 'ExecutorMvd' },
    executorLimits: { name: 'ExecutorLimits' },
    chatEdit: { name: 'ChatEdit' },
    galery: { name: 'Galery' },
    chatTemplate: { name: 'ChatTemplate' },
    chatMailing: { name: 'ChatMailing' },
    export: { name: 'Export' },
    chatExecutorDoc: { name: 'ChatExecutorDoc' },
    payGroup: { name: 'PayGroup' },
    integration: { name: 'Integration' },
    beelineUser: { name: 'BeelineUser' },
    dealGroup: { name: 'DealGroup' },
    dealTrigger: { name: 'DealTrigger' },
    task: { name: 'Task' },
} as const;

export default popups;
