import React from 'react';

import Avatar from '@components/avatar/Avatar.tsx';
import Link from '@components/link/Link.tsx';
import getFormatPrice from '@functions/getFormatPrice.ts';
import getFormatedDate from '@functions/getFormatedDate';
import getUserName from '@functions/getUserName.ts';
import setSpacesInText from '@functions/setSpacesInText';
import { setCursorSupport } from '@functions/supportHandler.ts';
import { ExecutorM } from '@global/models/Executor.ts';

import { RenderColsT, TableBlockCorporationT } from '../../types.ts';

const renderExecutors: RenderColsT<'executors'> = function ({
    name: colName,
    item,
    corporationIndex,
    ...props
}) {
    const { user } = this.state;
    const executor = item as ExecutorM;
    let content;
    let className;
    const corporation = props.corporation as TableBlockCorporationT<{
        balance: number;
        status: string;
        statusText: string;
        contract?: {
            status: string;
            number: number;
            statusText?: string;
            date: string;
        };
    }>;

    if (colName === 'type' && corporationIndex === 0) {
        className = '_hidden';
        content = <>{executor.typeName || '–'}</>;
    }

    if (colName === 'fullname' && corporationIndex === 0) {
        className = '_visible';

        const renderInfo = () => (
            <>
                <Link
                    className="v2table__text _link _full"
                    pageName="manual-executors-inner-main"
                    ids={{ 3: item._id }}
                    isStopPropagation={true}
                >
                    {getUserName({ user: executor }) || '–'}
                </Link>
                {user?.idOfCurrentCorporation === 'admin' && (
                    <>
                        , <span className="_noWrap">{executor.phone}</span>
                    </>
                )}
            </>
        );

        content = (
            <>
                {executor.logoSrc ? (
                    <div className={`v2table__preview _ROW`}>
                        <div className="v2table__previewAvatar">
                            <Avatar className="_circle _grey" image={executor.logoSrc} />
                        </div>
                        <div className="v2table__previewInfo">
                            <div className="v2table__previewInfoInner">{renderInfo()}</div>
                        </div>
                    </div>
                ) : (
                    renderInfo()
                )}
            </>
        );
    }

    if (colName === 'organization' && corporationIndex === 0) {
        const organization = executor.organization;
        const supportCursor =
            organization === 'SMZ' &&
            !executor.isJurStatusActive &&
            setCursorSupport(
                {
                    _id: `isJurStatusActive-${executor._id}`,
                    content: 'Исполнитель не подключён к партнеру<br/>CRM LIVECARGO в «Мой налог»',
                    dir: 'top',
                },
                { targetClassName: '.v2table__opf', className: '' },
            );

        content = (
            <>
                <div
                    className={`v2table__opf ${
                        organization === 'SMZ' && !executor.isJurStatusActive ? '_error' : ''
                    }`}
                    {...supportCursor}
                >
                    {executor.organizationName || '–'}
                </div>
            </>
        );
    }

    if (colName === 'phone' && corporationIndex === 0) {
        content = executor.phone;
    }

    if (colName === 'status' && corporation) {
        let statusClassName = '_wait';

        if (corporation.status === 'active') {
            statusClassName = '_active';
        }

        if (corporation.status === 'inactive') {
            statusClassName = '_wait';
        }

        if (corporation.status === 'reject') {
            statusClassName = '_alert';
        }

        if (corporation.status === 'process') {
            statusClassName = '';
        }

        content = (
            <div className="v2table__colInner">
                <div className={`v2table__status _block ${statusClassName}`}>
                    {corporation.statusText}
                </div>
            </div>
        );
    }

    if (colName === 'contract') {
        let statusClassName = '_default';
        let contractText;

        if (!corporation.contract || corporation.contract.status === 'inactive') {
            statusClassName = '_alert';
        }

        if (corporation.contract) {
            contractText = `№${
                corporation.contract?.number
            }, ${corporation.contract.statusText?.toLowerCase()}   ${
                corporation.contract.status && corporation.contract.date
                    ? ` ${getFormatedDate({
                          date: new Date(corporation.contract.date),
                          isShortYear: true,
                      })}`
                    : ''
            }`;
        }

        content = (
            <div className="v2table__colInner">
                <div className={`v2table__status ${statusClassName}`}>
                    {corporation.contract ? (
                        <>
                            <div
                                className="v2table__statusInner"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(contractText),
                                }}
                            ></div>
                        </>
                    ) : (
                        <>Не заключён</>
                    )}
                </div>
            </div>
        );
    }

    if (colName === 'balance') {
        content = (
            <div className={`v2table__text ${corporation.balance < 0 ? '_alertColor' : ''}`}>
                {`${getFormatPrice(+corporation.balance.toFixed(2))}₽`}
            </div>
        );
    }

    return { content, className };
};

export default renderExecutors;
