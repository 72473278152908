import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import setSpacesInText from '@functions/setSpacesInText.ts';
import DealTriggerT from '@global/models/DealTrigger.ts';

import icons from '../../../../../views/crm/recruit/components/dealGroup/components/trigger/static/icons.ts';

import { RenderColsT } from '../../types.ts';

const renderListDealTriggers: RenderColsT<'listDealTriggers'> = function ({ name: colName, item }) {
    let content;
    let className;

    const trigger = item as { _id: DealTriggerT['key']; name: string; description: string };

    if (colName === 'name') {
        const icon = icons[trigger._id];

        content = (
            <>
                <div className="v2table__preview _ROW">
                    <div className="v2table__previewAvatar _greyBack">
                        <i className="v2table__previewAvatarIcon">
                            <Icon name={icon} />
                        </i>
                    </div>
                    <div className="v2table__previewInfo _top">
                        <div className="v2table__previewInfoInner">
                            <div className="v2table__text _block _bottom">
                                <b>{trigger.name}</b>
                            </div>
                            <div
                                className="v2table__text _lightWeight _choiceWide"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(trigger.description),
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return { content, className };
};

export default renderListDealTriggers;
