import { FilterQueryT } from '@global/types';

import ManualTableI from '../types';
import beelineUsers from './tables/beelineUsers';
import chatExecutorDocs from './tables/chatExecutorDocs';
import chatMailings from './tables/chatMailings';
import chatTemplates from './tables/chatTemplates';
import cities from './tables/cities';
import corporationBalances from './tables/corporationBalances';
import corporationReports from './tables/corporationReports';
import corporationRoleSection from './tables/corporationRoleSection';
import corporationRoleSections from './tables/corporationRoleSections';
import corporationRoles from './tables/corporationRoles';
import corporations from './tables/corporations';
import countries from './tables/countries';
import docs from './tables/docs';
import executorTypes from './tables/executorTypes';
import executors from './tables/executors';
import executorsBalances from './tables/executorsBalances';
import executorsLimits from './tables/executorsLimits';
import executorsMvds from './tables/executorsMvds';
import executorsTaxes from './tables/executorsTaxes';
import exportItems from './tables/exportItems';
import integrations from './tables/integrations';
import joins from './tables/joins';
import joinsContracts from './tables/joinsContracts';
import joinsDocs from './tables/joinsDocs';
import joinsScripts from './tables/joinsScripts';
import joinsTemplates from './tables/joinsTemplates';
import listCities from './tables/lists/listCities';
import listCorporations from './tables/lists/listCorporations';
import listCountries from './tables/lists/listCountries';
import listCountryTypes from './tables/lists/listCountryTypes';
import listDealColors from './tables/lists/listDealColors';
import listDealGroups from './tables/lists/listDealGroups';
import listDealTriggers from './tables/lists/listDealTriggers';
import listDeals from './tables/lists/listDeals';
import listDocTypes from './tables/lists/listDocTypes';
import listExecutorMvdStatuses from './tables/lists/listExecutorMvdStatuses';
import listExecutorOrganizations from './tables/lists/listExecutorOrganizations';
import listExecutorTypes from './tables/lists/listExecutorTypes';
import listExecutors from './tables/lists/listExecutors';
import listIntegrationNames from './tables/lists/listIntegrationNames';
import listJoinScripts from './tables/lists/listJoinScripts';
import listJoinTemplates from './tables/lists/listJoinTemplates';
import listPassportViews from './tables/lists/listPassportViews';
import listPayProjects from './tables/lists/listPayProjects';
import listPays from './tables/lists/listPays';
import listRoles from './tables/lists/listRoles';
import listTags from './tables/lists/listTags';
import listTagsGroups from './tables/lists/listTagsGroups';
import listTagsGroupsColors from './tables/lists/listTagsGroupsColors';
import listTaskKeys from './tables/lists/listTaskKeys';
import listTaskModels from './tables/lists/listTaskModels';
import listTaskTimes from './tables/lists/listTaskTimes';
import listUsers from './tables/lists/listUsers';
import modelLogs from './tables/modelLogs';
import payProjects from './tables/payProjects';
import pays from './tables/pays';
import paysGroups from './tables/paysGroups';
import popupChatTemplates from './tables/popupChatTemplates';
import recruitDealGroups from './tables/recruitDealGroups';
import signJoinsContracts from './tables/signJoinsContracts';
import signPays from './tables/signPays';
import staticList from './tables/staticList';
import tags from './tables/tags';
import tasks from './tables/tasks';
import users from './tables/users';

type TableColT = {
    content: string | ((admin: boolean, props: ManualTableI['props']['otherData']) => string);
    width: number | ((admin: boolean) => number);
    isAdminMain?: boolean;
    isRemove?: boolean;
};

type TableT = {
    colsOrder: readonly string[];
    cols: Record<string, TableColT>;
    adminIndex?: number;
    deletesCols: readonly string[];
    url?: string;
    query?: FilterQueryT[];
    emptyInfo: {
        title: string;
        description?: string;
    };
};

const tables = {
    executors,
    modelLogs,
    joins,
    corporationBalances,
    users,
    corporationReports,
    corporations,
    pays,
    paysGroups,
    joinsContracts,
    joinsScripts,
    joinsTemplates,
    joinsDocs,
    executorsMvds,
    executorsTaxes,
    executorsLimits,
    tags,
    executorsBalances,
    chatMailings,
    chatTemplates,
    popupChatTemplates,
    signPays,
    signJoinsContracts,
    payProjects,
    staticList,
    corporationRoles,
    corporationRoleSections,
    corporationRoleSection,
    docs,
    cities,
    countries,
    executorTypes,
    exportItems,
    chatExecutorDocs,
    integrations,
    beelineUsers,
    recruitDealGroups,
    tasks,

    listTagsGroups,
    listTagsGroupsColors,
    listExecutors,
    listCorporations,
    listJoinScripts,
    listUsers,
    listJoinTemplates,
    listPayProjects,
    listRoles,
    listCountries,
    listPassportViews,
    listCountryTypes,
    listTags,
    listExecutorTypes,
    listExecutorOrganizations,
    listCities,
    listDocTypes,
    listExecutorMvdStatuses,
    listIntegrationNames,
    listDealGroups,
    listDealColors,
    listDealTriggers,
    listTaskKeys,
    listTaskTimes,
    listPays,
    listDeals,
    listTaskModels,
} as const;

export default tables;

export type { TableT, TableColT };
