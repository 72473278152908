const beelineUsers = {
    colsOrder: ['phone', 'user'],
    cols: {
        phone: {
            content: 'Телефон',
            width: 30,
        },
        user: {
            content: 'Сотрудник',
            width: 70,
        },
    },
    deletesCols: [],
    url: 'beelineUser',
    emptyInfo: {
        title: 'Аккаунтов пока нет',
        description: 'Вы можете привязать аккаунт по кнопке справа',
    },
} as const;

export default beelineUsers;
