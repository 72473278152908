import React from 'react';

import Avatar from '@components/avatar/Avatar.tsx';
import UserT from '@global/models/User.ts';

import { RenderColsT } from '../../types.ts';

const renderListUsers: RenderColsT<'listUsers'> = function ({ name: colName, item }) {
    let content;
    let className;

    const user = item as Pick<
        UserT,
        '_id' | 'firstName' | 'secondName' | 'thirdName' | 'fullName' | 'email' | 'logoSrc'
    > & {
        roleName?: string;
    };

    if (colName === 'name') {
        className = '_full';

        const text = (
            <>
                <div className="v2table__text">{user.fullName},</div>{' '}
                <div className="v2table__text _grey _NOWRAP">{user.email}</div>
                {user.roleName && <div className="v2table__text">, {user.roleName}</div>}
            </>
        );

        content = (
            <>
                {user.logoSrc ? (
                    <div className={`v2table__preview _ROW`}>
                        <div className="v2table__previewAvatar">
                            <Avatar className="_circle _grey" image={user.logoSrc} />
                        </div>
                        <div className="v2table__previewInfo">
                            <div className="v2table__previewInfoInner">{text}</div>
                        </div>
                    </div>
                ) : (
                    <>{text}</>
                )}
            </>
        );
    }

    return { content, className };
};

export default renderListUsers;
