import lists from '../lists';

const listPays = {
    ...lists,
    query: [{ key: 'type', value: 'pays' }],
    emptyInfo: {
        title: 'Актов пока нет',
        description: 'Они будут отображаться здесь',
    },
} as const;

export default listPays;
