import React from 'react';

import { RenderColsT } from '../../types.ts';

const renderListTaskTimes: RenderColsT<'listTaskTimes'> = function ({ name: colName, item }) {
    let content;
    let className;

    const taskTime = item as {
        _id: string;
        name: string;
    };

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text">{taskTime.name}</div>
            </>
        );
    }

    return { content, className };
};

export default renderListTaskTimes;
