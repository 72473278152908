import React, { ElementType } from 'react';

import Animate from '@components/animate/Animate.tsx';
import Button from '@components/button/Button.tsx';
import setSpacesInText from '@functions/setSpacesInText.ts';

import InfoBlockI from './types.ts';

class InfoBlock extends React.Component<InfoBlockI['props']> implements InfoBlockI {
    constructor(props: InfoBlockI['props']) {
        super(props);
        this.state = {};
    }

    render() {
        const { className = '', title, description, isShow, isStatic, icon, button } = this.props;
        let InfoTag: ElementType = isShow === undefined ? 'div' : Animate;
        const props = isShow === undefined ? {} : { isShow: !!isShow };

        if (isStatic) {
            InfoTag = React.Fragment;
        }

        return (
            <InfoTag className="_INFOBLOCKWRAPPER" {...props}>
                <div className={`v2infoBlock ${className}`}>
                    <div className="v2infoBlock__inner _COL">
                        {icon && <img src={icon} alt="" className="v2infoBlock__icon" />}
                        <div
                            className="v2infoBlock__title"
                            dangerouslySetInnerHTML={{ __html: setSpacesInText(title) }}
                        ></div>
                        {description && (
                            <div
                                className="v2infoBlock__description"
                                dangerouslySetInnerHTML={{ __html: setSpacesInText(description) }}
                            ></div>
                        )}
                        {button && (
                            <div className="v2infoBlock__button">
                                <Button className="_main _mediumSize _shadow" onClick={button.onClick}>
                                    {button.text}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </InfoTag>
        );
    }
}

export default InfoBlock;
